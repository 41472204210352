
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import moment from "moment";
import TableActions from "../table-actions";
import TableFilters from "../table-filters";
import SkeletonLines from "../skeleton/skeleton-lines";
import SkeletonTable from "../skeleton/skeleton-table";
import modals from "../../mixins/modals";
import QuintableSearchInput from "../helpers/quintable-search-input.vue";
import DownloadSelectColumnsModal from "../download-select-columns-modal.vue";
import cachedCompanyProvider from "../../mixins/cached-company-provider";
import EditCallLogModalV2 from "../call-log/edit-call-log-modal";
import callLog from "../../mixins/call-log";
import ShowInteractionModal from "./show-interaction-modal";
import InteractionBulkOperationModal from "./interaction-bulk-operation-modal";
import SyncModal from "./sync-modal";
import InteractionDateCell from "./interactions-table-cells/interaction-date-cell.vue";
import InteractionParticipantsCell from "./interactions-table-cells/interaction-participants-cell.vue";
import InteractionRelevanceCell from "./interactions-table-cells/interaction-relevance-cell.vue";
import InteractionSubjectCell from "./interactions-table-cells/interaction-subject-cell.vue";
import InteractionLocationCell from "./interactions-table-cells/interaction-location-cell.vue";
import InteractionEventCell from "./interactions-table-cells/interaction-event-cell.vue";
import InteractionIndicatorsCell from "./interactions-table-cells/interaction-indicators-cell.vue";
import InteractionDetailsCell from "./interactions-table-cells/interaction-details-cell.vue";
import InteractionNotesCell from "./interactions-table-cells/interaction-notes-cell.vue";
import InteractionSummaryCell from "./interactions-table-cells/interaction-summary-cell.vue";
import InteractionFollowUpCell from "./interactions-table-cells/interaction-follow-up-cell.vue";
import SyncCopyModal from "./sync-copy-modal.vue";
import DownloadScheduleModal from "./download-schedule-modal.vue";

import optionButtonTooltip from "~/mixins/option-button-tooltip";

export default {
    name: "InteractionsTable",
    components: {
        DownloadScheduleModal,
        DownloadSelectColumnsModal,
        SyncCopyModal,
        QuintableSearchInput,
        InteractionFollowUpCell,
        InteractionSummaryCell,
        InteractionNotesCell,
        InteractionDetailsCell,
        InteractionIndicatorsCell,
        InteractionLocationCell,
        InteractionEventCell,
        InteractionSubjectCell,
        InteractionRelevanceCell,
        InteractionParticipantsCell,
        InteractionDateCell,
        SyncModal,
        InteractionBulkOperationModal,
        SkeletonTable,
        SkeletonLines,
        TableFilters,
        TableActions,
        flatPickr,
        ShowInteractionModal,
        EditCallLogModalV2,
    },
    mixins: [optionButtonTooltip, modals, cachedCompanyProvider, callLog],
    props: {
        company: {
            type: Object,
            default: null
        },
        contact: {
            type: Object,
            default: null,
        },
        event: {
            type: Object,
            default: null,
        },
        userId: {
            type: Number,
            default: null,
        },
        onlyMyInteractions: {
            type: Boolean,
            default: true,
        },
        future: {
            type: Boolean,
            default: false
        },
        addNoteLoading: {
            type: Number,
            default: null
        },
        deleteLoading: {
            type: Number,
            default: null
        },
        cancelLoading: {
            type: Number,
            default: null,
        },
        editLoading: {
            type: Number,
            default: null
        },
        tableUpdate: {
            type: Number,
            default: null,
        },
        futureDaysLimit: {
            type: Number,
            default: null
        },
        overview: {
            type: Boolean,
            default: false
        },
        enableSelect: {
            type: Boolean,
            default: false
        },
        showEdit: {
            type: Boolean,
            default: true,
        },
        allowEdit: {
            type: Boolean,
            default: true,
        },
        showDelete: {
            type: Boolean,
            default: true,
        },
        showAddNote: {
            type: Boolean,
            default: true,
        },
        allowEditNote: {
            type: Boolean,
            default: true,
        },
        showIcalDownload: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            callLogShow: false,
            callLog: null,
            interactionsUpdated: this.generateUUID(),
            selectedCompanies: [],
            selectedParticipants: [],
            selectedTypes: [],
            filterOptions: {
                companies: [],
                participants: [],
                types: [],
            },
            onlyABC: false,
            onlyMy: this.onlyMyInteractions,
            upcoming: this.event ? !this.event.isPast : true,
            hideEmails: true,
            hideCanceled: true,
            onlyRelevant: false,
            onlyWithSummary: false,
            forceUpdate: moment().valueOf() / 1000,
            pageChanged: false,
            tableLoaded: false,
            selectedRows: [],
            preSelectedRows: [],
            preSelectedRowIds: [],
            ajaxRows: [],
            ajaxAll: 0,
            mode: 'filtered',
            bulkUpdated: moment().valueOf(),
            bulkTriggered: false,
            bulkModal: null,
            searchQuery: "",
            checkBackInFrom: null,
            checkBackInTo: null,
            minimumDate: null,
            maximumDate: null,
            dateConfig: {
                wrap: true,
                altInput: true,
                altFormat: "m/d/Y",
                dateFormat: "Y-m-d",
            },
            interactionScheduleModal: null,
            interactionSyncModal: null,
            interactionToSync: null,
            downloadQuery: null,
            interactionToShow: null,
            interactionToCopy: null,
            interactionCopyModal: null,
            hideBlockers: !this.event,
            interactionsSyncing: false,
            excelExportQuery: {},
            interactionsDownloadingFromSessions: false,
            callLogModalKey: 'callLogModalKey'
        }
    },
    computed: {
        config() {
            return {
                columns: [
                    {
                        headline: "Date",
                        sort: true,
                        ignoreEmpty: true,
                    }, {
                        headline: "Subject",
                        sort: true,
                        ignoreEmpty: true,
                        breakpoint: "sm",
                    }, {
                        headline: "Participants",
                        breakpoint: "md",
                        sort: false,
                        ignoreEmpty: true,
                    }, {
                        headline: "Type",
                        breakpoint: "sm",
                        sort: true,
                        ignoreEmpty: true,
                    }, {
                        headline: "Location",
                        sort: false,
                        ignoreEmpty: true,
                        breakpoint: this.upcoming ? "xl" : "all",
                    }, {
                        headline: "Event",
                        sort: false,
                        breakpoint: "sm",
                        hidden: this.event
                    }, {
                        headline: "Relevance",
                        breakpoint: "all",
                    }, {
                        headline: "Visibility",
                        breakpoint: "all",
                        sort: false,
                        ignoreEmpty: true,
                    }, {
                        headline: "Notes",
                        breakpoint: this.upcoming ? "all" : "xl",
                        hideHeadlineBreakpoint: "all",
                    }, {
                        headline: "Notes Button and other Icons",
                        hideHeadlineBreakpoint: "all",
                        align: "end",
                        hidden: !this.allowEdit,
                    }, {
                        headline: "Actions",
                        hideHeadlineBreakpoint: "all",
                        align: 'end',
                        ignoreEmpty: true,
                    }, {
                        headline: "Details",
                        breakpoint: "all",
                    }, {
                        headline: "Summary",
                        breakpoint: "all"
                    }, {
                        headline: "Check back in",
                        breakpoint: "all"
                    }, {
                        headline: "Follow-up Item",
                        breakpoint: "all"
                    },
                ],
                select: this.enableSelect,
                selectPosition: "pre",
                selectAll: this.enableSelect,
                prettySelect: this.enableSelect,
                pageSortSelect: this.enableSelect,
                pagination: 10,
                hideEmptyColumns: false,
                search: this.overview,
                ajaxUrl: '/api/interactions/list',
                searchContainerClass: "search-container",
            };
        },
        userIdFinal() {
            if (this.userId > 0) {
                return this.userId;
            }
            if (this.onlyMy) {
                return this.userInfo.id;
            }
            return 0;
        },
        validConfig() {
            return this.overview || (this.company && this.company.id > 0) || this.userIdFinal > 0 || (this.contact && this.contact.id > 0);
        },
        selectedCompanyIDs() {
            return this.selectedCompanies.map(x => x.id);
        },
        selectedParticipantIDs() {
            return this.selectedParticipants.map(x => x.id);
        },
        filters() {
            let isFuture;
            if (this.overview) {
                if (this.minimumDate == null && this.maximumDate == null) {
                    isFuture = (this.event && this.event.isPast) ? null : this.upcoming;
                } else {
                    isFuture = null;
                }
            } else {
                isFuture = this.future;
            }

            return {
                company: this.company ? this.company.id : null,
                contact: this.contact ? this.contact.id : null,
                event: this.event ? this.event.id : null,
                userId: this.userIdFinal,
                onlyABC: this.onlyABC ? this.userInfo.id : null,
                minimumDate: this.minimumDate,
                maximumDate: this.maximumDate,
                future: isFuture,
                futureDaysLimit: this.futureDaysLimit,
                companies: this.selectedCompanyIDs,
                contacts: this.selectedParticipantIDs,
                types: this.selectedTypes,
                hideEmails: this.hideEmails,
                hideCanceled: this.hideCanceled,
                checkBackInFrom: this.checkBackInFrom,
                checkBackInTo: this.checkBackInTo,
                withSummary: this.onlyWithSummary,
                relevantOnly: this.onlyRelevant,
                hideBlockers: this.hideBlockers,

            }
        },
        updateTable() {
            // Table can be updated from outside (property: tableUpdate) or inside (forceUpdate).
            // Here we decide which one to use
            return new Date(
                Math.max(this.tableUpdate, this.forceUpdate)
            );
        },
        bulkFilters() {
            switch (this.mode) {
                case "selected":
                    return {
                        ids: JSON.parse(JSON.stringify(this.preSelectedRowIds)),
                        // userId: this.userInfo.id
                    }
                case "page":
                    return {
                        ids: this.ajaxRows.map((row) => row.interaction_id),
                        // userId: this.userInfo.id
                    }
                case "filtered":
                default:
                    if (this.searchQuery) {
                        // return {...this.filters, search_term: this.searchQuery,userId: this.userInfo.id}
                        return {...this.filters, search_term: this.searchQuery}
                    }
                    // return {...this.filters,userId: this.userInfo.id};
                    return this.filters;
            }
        },
        currentMinimumDate: {
            get() {
                return this.minimumDate;
            },
            set(val) {
                if (this.maximumDate == null) {
                    // No maximum date to compare, just update minimum date.
                    this.minimumDate = val;
                } else {
                    // Compare new minimum and current maximum date.
                    const parsedMaximumDate = moment(this.maximumDate, "YYYY-MM-DD");
                    const parsedMinimumDate = moment(val, "YYYY-MM-DD");
                    if (parsedMaximumDate >= parsedMinimumDate) {
                        // Maximum date later than minimum update, just update minimum date.
                        this.minimumDate = val;
                    } else {
                        // Maximum date earlier than minimum date, swap them and update.
                        this.minimumDate = parsedMaximumDate.format("YYYY-MM-DD");
                        this.maximumDate = val;
                    }
                }
            },
        },
        currentMaximumDate: {
            get() {
                return this.maximumDate;
            },
            set(val) {
                if (this.minimumDate == null) {
                    // No minimum date to compare, just update maximum date.
                    this.maximumDate = val;
                } else {
                    // Compare new maximum and current minimum date.
                    const parsedMaximumDate = moment(val, "YYYY-MM-DD");
                    const parsedMinimumDate = moment(this.minimumDate, "YYYY-MM-DD");
                    if (parsedMaximumDate >= parsedMinimumDate) {
                        // Maximum date later than minimum update, just update maximum date.
                        this.maximumDate = val;
                    } else {
                        // Maximum date earlier than minimum date, swap them and update.
                        this.maximumDate = parsedMinimumDate.format("YYYY-MM-DD");
                        this.minimumDate = val;
                    }
                }
            },
        },
        companyParams() {
            if (this.event) {
                return {
                    eventId: this.event.id
                }
            } else {
                return null;
            }
        }
        // sortOrder() {
        //     return [{
        //         index: 0,
        //         asc: this.upcoming === true,
        //     }];
        // },
    },
    watch: {
        // preSelectedRowIds: {
        //     handler(val) {
        //         this.$emit("input", JSON.parse(JSON.stringify(val)))
        //     },
        //     deep: true
        // },
        selectedRows(rows) {
            if (!this.pageChanged && this.ajaxRows) {

                for (let i = 0; i < rows.length; i++) {
                    if (!this.preSelectedRowIds.includes(rows[i].interaction_id)) {
                        this.preSelectedRowIds.push(rows[i].interaction_id);
                    }
                }

                for (let j = 0; j < this.ajaxRows.length; j++) {
                    const id = this.ajaxRows[j].interaction_id;

                    const index = this.preSelectedRowIds.indexOf(id);

                    if (!rows.map(r => r.interaction_id).includes(id) && index !== -1) {
                        this.preSelectedRowIds.splice(index, 1);
                    }
                }
            }

        },
    },
    mounted() {
        this.initializeOptionButtonTooltips();
        if (this.overview) {
            this.loadInteractionFilterOptions();
        }

        if (this.$route.query.hasOwnProperty('company') && this.$route.query.company > 0) {
            this.getCompanyById(this.$route.query.company).then((company) => {
                this.selectedCompanies.push(company);
            });
        }


    },
    methods: {
        showCallLog(callLog) {
            this.$axios.get(`/api/call_logs/${callLog}`)
                .then((response) => {
                    this.callLog = response.data;
                    this.callLogShow = true;
                }).finally(async() => {
                    this.callLogModalKey = this.generateUUID();
                    await this.$nextTick();
                    this.editCallLogModal = this.openModal(this.$refs.editCallLogModalV2);
                });

        },

        filterCompanyParticipants(participants, companyId) {
            return participants.filter((p) => p.contact && p.contact.company && p.contact.company.id === companyId)
        },
        filterNonCompanyParticipants(participants) {
            return participants.filter((p) => p.contact && !p.contact.company)
        },
        onUpdate(data) {
            this.tableLoaded = true;

            this.pageChanged = false;
            if (data && data.rows && data.rows.length) {
                this.$nextTick(() => {

                    this.preSelectedRows = JSON.parse(JSON.stringify(this.preSelectedRowIds)).map(id => {
                        return {
                            key: "interaction_id",
                            value: id
                        }
                    });

                });
            }
            this.ajaxRows = data.rows;
            this.ajaxAll = data.all;

            setTimeout(() => this.initializeOptionButtonTooltips('rows updated'), 250);

        },
        addNoteToInteraction(interactionId) {
            this.$emit("add-note-to-interaction", interactionId);
        },
        showNoteOfInteraction({noteId, interactionId}) { // This will receive only one parameter!
            this.$emit("edit-note-of-interaction", {noteId, interactionId});
        },
        editInteraction(id) {
            this.$emit("edit-interaction", id);
        },
        cancelInteraction(id) {
            this.$emit("cancel-interaction", id);
        },
        deleteInteraction(id) {
            this.$emit("delete-interaction", id);
        },
        loadInteractionFilterOptions() {
            this.$axios.get(`/api/interactions/filter_options?eventId=${this.event ? this.event.id : null}`)
                .then((response) => {
                    this.filterOptions = response.data;
                });
        },
        resetFilters() {
            this.interactionsUpdated = this.generateUUID();

            this.selectedCompanies = [];
            this.selectedParticipants = [];
            this.selectedTypes = [];

            this.onlyABC = false;
            this.onlyMy = this.onlyMyInteractions;
            this.hideEmails = true;
            this.hideCanceled = true;
            this.onlyRelevant = false;
            this.onlyWithSummary = false;
            this.hideBlockers = true;

            this.upcoming = true;
            this.minimumDate = null;
            this.maximumDate = null;
            this.checkBackInFrom = null;
            this.checkBackInTo = null;
        },
        forceTableUpdate() {
            // Update the property to update the table. As this value might be compared to a unix timestamp in seconds
            // which comes from outside as a property we need to convert it to seconds while keeping the same precision.
            this.forceUpdate = moment().valueOf() / 1000;
        },
        onPageChange() {
            this.pageChanged = true;
        },
        clearPreSelection() {
            this.preSelectedRowIds = [];
            this.preSelectedRows = [];
        },
        openBulkOperationModal(mode) {
            this.mode = mode;
            this.bulkUpdated = moment().valueOf();
            this.bulkTriggered = true;

            this.$nextTick(() => {
                this.bulkModal = this.openModal(this.$refs.interactionBulkOperationModal)
            })
        },
        onBulkSubmitted(update = true) {
            if (update) {
                this.forceTableUpdate();
            }
            this.closeModal(this.bulkModal);
        },
        updateSearchQuery(searchQuery) {
            this.searchQuery = searchQuery;
        },
        /* changeVisibility(id) {
            this.$emit('visibility-updated', id);
        } */
        downloadInteractions() {
            this.excelExportQuery = {
                search: this.searchQuery,
                filters: this.filters,
                sort: this.sortOrder,
            }
            this.interactionScheduleModal = this.openModal(this.$refs.downloadSchuduleModal);
        },
        interactionScheduleDownloadTriggered() {
            this.closeModal(this.interactionScheduleModal);
        },
        addInteractionToOutlook(interactionId) {
            this.$axios.get(`/api/interactions/${interactionId}`)
                .then(async (response) => {
                    this.interactionToSync = response.data;
                    await this.$nextTick();
                    this.interactionSyncModal = this.openModal(this.$refs.syncInteractionModal);
                });
        },
        addInteractionCopyToOutlook(interactionId) {
            this.$axios.get(`/api/interactions/${interactionId}`)
                .then(async (response) => {
                    this.interactionToCopy = response.data;
                    await this.$nextTick();
                    this.interactionCopyModal = this.openModal(this.$refs.copyInteractionModal);
                });
        },
        onInteractionSynced() {
            this.closeModal(this.interactionSyncModal);
            this.interactionsUpdated = this.generateUUID();
        },
        onInteractionCopied() {
            this.closeModal(this.interactionCopyModal);
            this.interactionsUpdated = this.generateUUID();
        },
        downloadBulkContacts(filters) {
            this.closeModal(this.bulkModal);
            this.downloadQuery = {
                filters
            };
            this.openModal(this.$refs.downloadContactsModal);
        },
        showInteraction(id) {
            this.interactionToShow = id;
            this.$nextTick(() => {
                this.openModal(this.$refs.showInteractionsModal);
            })
        },
        syncEventInteractions() {
            this.interactionsSyncing = true;
            this.$axios.get(`/api/events/${this.event.id}/sync_interactions`)
                .then((response) => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: response.data.message,
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                        this.interactionsUpdated = this.generateUUID();
                    });
                })
                .finally(() => {
                    this.interactionsSyncing = false;
                });
        },
        sortUpdate(order) {
            this.sortOrder = order;
        },
        downloadInteractionsFromSessions() {
            if (!this.event || !this.event.sessionsId) {
                return;
            }

            this.interactionsDownloadingFromSessions = true;
            this.$axios.post(`/api/sessions/meetings/import/${this.event.id}`)
                .then(() => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Meeting will be imported in the background",
                        message: '',
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                })
                .finally(() => {
                    this.interactionsDownloadingFromSessions = false;
                });
        },
        closeCallLogModal() {
            if (this.editCallLogModal) {
                this.closeModal(this.editCallLogModal);
                this.editCallLogModal = null;
            }
        },
        onUpdated() {
            this.forceTableUpdate();
            this.closeCallLogModal();
        },
        deleteCallLogFromModal(id) {
            console.log('deleteCallLogFromModal', id);
            this.closeCallLogModal();
            this.doDeleteCallLog(id);
        }
    }
}

