
export default {
    name: "EditBaseGroupFields",
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            group: this.value,
            visibilityOptions: [{value: false, label: 'public'}, {value: true, label: 'personal'}],
            typing: Date.now(),
        }
    },
    computed: {
        userSelectParams() {
            return {
                exclude: this.group.user ? this.group.user.id() : null
            }
        }
    },
    watch: {
        group: {
            handler(val) {
                this.$emit("input", val)
            },
            deep: true,
        },
    },
    methods: {
        atVisibilityChange(isPrivate) {
            if (!isPrivate) {
                this.group.sharedWithUsers = [];
            }
        },
        emitTitleChange(val) {
            this.typing = Date.now();
            setTimeout(() => {
                if (Date.now() - this.typing >= 300) {
                    this.$emit("title-change", val);
                }
            }, 333);
        }
    }
}
