import { render, staticRenderFns } from "./favorite-pages-card.vue?vue&type=template&id=754f9909&scoped=true"
import script from "./favorite-pages-card.vue?vue&type=script&lang=js"
export * from "./favorite-pages-card.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "754f9909",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TableActions: require('/codebuild/output/src3271430876/src/web/components/table-actions.vue').default,EditFavoritePageModal: require('/codebuild/output/src3271430876/src/web/components/edit-favorite-page-modal.vue').default,ConfirmModal: require('/codebuild/output/src3271430876/src/web/components/confirm-modal.vue').default})
